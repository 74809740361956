/**
 * An extension of projectGraphV2 that expands the valid nodes to include automatic params, secrets, and data connections
 */

import assert from "assert";

import { assertIsDefined } from "../assertions";
import {
  AUTOMATIC_PARAMETERS,
  AUTOMATIC_PARAMETER_NAMES,
} from "../automaticParameters";
import { CellType } from "../enums";
import { assertNever } from "../errors";
import { asciiCompare } from "../fractionalIndexing";
import {
  BlockCellId,
  CellId,
  ComponentImportCellId,
  DataConnectionId,
  SecretId,
} from "../idTypeBrands";
import { notEmpty } from "../notEmpty";
import { SecretName, VariableName } from "../typeBrands";
import {
  typedObjectEntries,
  typedObjectFromEntries,
  typedObjectValues,
} from "../utils/typedObjects";

import { getFlattenedSortedCells } from "./cellOrder.js";
import { ComputedCellReferencesV3 } from "./cellReferencesV3";
import { ReactiveParamType } from "./projectGraphs";
import {
  BaseGraphCell,
  CellGraph,
  GraphNode,
  InputParamInfo,
  OutputParamInfo,
} from "./projectGraphV2";

export type BaseGraphCellV3 = {
  id: CellId;
  cellType?: CellType | null;

  parentComponentImportCellId?: ComponentImportCellId | null;
  componentImportCellId?: ComponentImportCellId | null;
  parentBlockCellId?: BlockCellId | null;
  blockCellId?: BlockCellId | null;

  /**
   * @deprecated This is the raw `order` field from the cell entity, which cannot be
   * relied upon to compare the positions of "child" cells of different parents (e.g.
   * cells imported by components, or in blocks). Use the `order` field on the
   * containing `CellGraphNodeV3` instead.
   */
  order: string;
};

export type BaseGraphSecretV3 = {
  id: SecretId;
  name: SecretName;
};

export interface InputParamInfoV3 {
  parentNodeId: GraphNodeV3Id | undefined;
  /** If this input is tied to a specific data connection id, refer to that */
  dataConnectionId?: DataConnectionId;
  type: ReactiveParamType;
}

export interface OutputParamInfoV3 {
  children: CellId[];
  /** The graph node that this param is redefining */
  shadowedNodeId?: GraphNodeV3Id;
  /** If this output is tied to a specific data connection id, refer to that */
  dataConnectionId?: DataConnectionId;
  type: ReactiveParamType;
}

export interface CellGraphNodeV3<C extends BaseGraphCellV3> {
  cellId: CellId;
  inputParams: Map<VariableName, InputParamInfoV3>;
  outputParams: Map<VariableName, OutputParamInfoV3>;
  cell: C;
  order: string;
  type: "cell";
}

export interface DataConnectionGraphNodeV3 {
  dataConnectionId: DataConnectionId;
  outputTables: Map<VariableName, OutputParamInfoV3>;
  type: "dataConnection";
}

export interface SecretGraphNodeV3 {
  secretId: SecretId;
  secretName: SecretName;
  output: OutputParamInfoV3;
  type: "secret";
}

export interface AutomaticParameterGraphNodeV3 {
  parameterName: AUTOMATIC_PARAMETER_NAMES;
  output: OutputParamInfoV3;
  type: "automaticParameter";
}

export type GraphV3<C extends BaseGraphCellV3> = Record<
  CellId,
  CellGraphNodeV3<C>
> &
  Record<DataConnectionId, DataConnectionGraphNodeV3> &
  Record<AUTOMATIC_PARAMETER_NAMES, AutomaticParameterGraphNodeV3> &
  Record<SecretId, SecretGraphNodeV3>;

export type GraphNodeV3Id = keyof GraphV3<BaseGraphCellV3>;
export type GraphNodeV3<C extends BaseGraphCellV3> = GraphV3<C>[GraphNodeV3Id];
export type GraphNodeV3Type = GraphNodeV3<BaseGraphCellV3>["type"];

export type ComputedCellReferenceMapV3<C> = Record<
  CellId,
  { cell: C; references: ComputedCellReferencesV3[] }
>;

export interface GraphDataV3<C extends BaseGraphCellV3> {
  graph: GraphV3<C>;
  redefinitions: Map<VariableName, GraphNodeV3Id[]>;
  missingDefinitions: Map<VariableName, GraphNodeV3Id[]>;
}

const TYPE_ORDER: Record<GraphNodeV3Type, number> = {
  automaticParameter: 1,
  secret: 2,
  dataConnection: 3,
  cell: 4,
};

export const sortGraphNodeV3s = <C extends BaseGraphCellV3>(
  a: GraphNodeV3<C>,
  b: GraphNodeV3<C>,
): number => {
  // Sort by type if they're different, otherwise sort by id within each section
  if (a.type !== b.type) {
    return TYPE_ORDER[a.type] - TYPE_ORDER[b.type];
  } else {
    switch (a.type) {
      case "automaticParameter":
        assert(b.type === a.type);
        return a.parameterName.localeCompare(b.parameterName);
      case "secret":
        assert(b.type === a.type);
        return a.secretId.localeCompare(b.secretId);
      case "dataConnection":
        assert(b.type === a.type);
        return a.dataConnectionId.localeCompare(b.dataConnectionId);
      case "cell":
        assert(b.type === a.type);
        return asciiCompare(a.order, b.order);
      default:
        assertNever(a, a);
    }
  }
};

export const upgradeInputParamInfoV2 = (
  input: InputParamInfo,
): InputParamInfoV3 => {
  return {
    parentNodeId: input.parentCellId,
    type: input.type,
  };
};

export const upgradeGraphNodeV2 = <C extends BaseGraphCell>(
  node: GraphNode<C>,
): CellGraphNodeV3<C> => {
  return {
    type: "cell",
    cellId: node.cell.id,
    cell: node.cell,
    order: node.cell.order,
    inputParams: new Map(
      typedObjectEntries(node.inputParams).map(
        ([key, value]) => [key, upgradeInputParamInfoV2(value)] as const,
      ),
    ),
    outputParams: new Map(
      typedObjectEntries(node.outputParams) as [
        VariableName,
        OutputParamInfo,
      ][],
    ),
  };
};

export const upgradeGraphV2 = <C extends BaseGraphCell>(
  graph: CellGraph<C>,
): GraphV3<C> => {
  const baseGraph = getBaseGraphV3<C>();
  typedObjectEntries(graph).forEach(([cellId, graphNode]) => {
    baseGraph[cellId] = upgradeGraphNodeV2(graphNode);
  });
  return baseGraph;
};

export const getGraphNodeV3Id = <C extends BaseGraphCellV3>(
  node: GraphNodeV3<C>,
): GraphNodeV3Id => {
  switch (node.type) {
    case "cell":
      return node.cell.id;
    case "automaticParameter":
      return node.parameterName; // It's fine to use the name as the id, since there is no chance of collisions
    case "dataConnection":
      return node.dataConnectionId;
    case "secret":
      return node.secretId;
    default:
      assertNever(node, node);
  }
};

export function getGraphNodeV3Inputs<C extends BaseGraphCellV3>(
  node: GraphNodeV3<C>,
  filterParamTypes: ReactiveParamType[] = [],
): Map<VariableName, InputParamInfoV3> {
  switch (node.type) {
    case "automaticParameter":
    case "dataConnection":
    case "secret":
      return new Map();
    case "cell": {
      return new Map(
        Array.from(node.inputParams).filter(
          ([, inputParamInfo]) =>
            !filterParamTypes.includes(inputParamInfo.type),
        ),
      );
    }
  }
}

export function getGraphNodeV3Outputs<C extends BaseGraphCellV3>(
  node: GraphNodeV3<C>,
  filterParamTypes: ReactiveParamType[] = [],
): Map<VariableName, OutputParamInfoV3> {
  switch (node.type) {
    case "automaticParameter":
      return new Map([[node.parameterName as VariableName, node.output]]);
    case "dataConnection":
      return new Map(node.outputTables);
    case "secret":
      return new Map([[node.secretName, node.output]]);
    case "cell": {
      return new Map(
        Array.from(node.outputParams).filter(
          ([, outputParamInfo]) =>
            !filterParamTypes.includes(outputParamInfo.type),
        ),
      );
    }
  }
}

export function getGraphNodeV3Output<C extends BaseGraphCellV3>(
  node: GraphNodeV3<C>,
  param: VariableName,
): OutputParamInfoV3 | null {
  return getGraphNodeV3Outputs(node).get(param) ?? null;
}

type ParentNodeFilter<C extends BaseGraphCellV3> = (
  node: GraphNodeV3<C>,
  param: VariableName,
) => boolean;

function getParamTypeFilter<C extends BaseGraphCellV3>(
  paramTypeFilter: ReactiveParamType[],
): ParentNodeFilter<C> {
  return (node, param) => {
    const outputs = getGraphNodeV3Outputs(node).get(param);
    const type = outputs?.type;
    return type != null && paramTypeFilter.includes(type);
  };
}

function getBaseGraphV3<C extends BaseGraphCellV3>(): GraphV3<C> {
  return typedObjectFromEntries(
    AUTOMATIC_PARAMETERS.map(
      (p): [AUTOMATIC_PARAMETER_NAMES, AutomaticParameterGraphNodeV3] => [
        p.name,
        {
          parameterName: p.name,
          output: {
            children: [],
            type: ReactiveParamType.AUTOMATIC_PARAM,
          },
          type: "automaticParameter",
        },
      ],
    ),
  );
}

export function getProjectGraphV3<C extends BaseGraphCellV3>({
  cells,
  dataConnections,
  secrets,
}: {
  cells: ComputedCellReferenceMapV3<C>;
  secrets: Array<BaseGraphSecretV3>;
  dataConnections: DataConnectionId[];
}): GraphDataV3<C> {
  const sortedCells = getFlattenedSortedCells(
    typedObjectValues(cells).map(({ cell }) => cell),
  );

  // Values are stored in reverse order where most recent definition is index 0 and first definition is the last index
  const paramDefinitions = new Map<VariableName, GraphNodeV3Id[]>();
  const graph: GraphV3<C> = getBaseGraphV3();
  AUTOMATIC_PARAMETERS.forEach((param) => {
    paramDefinitions.set(param.name as VariableName, [param.name]);
  });
  secrets.forEach((secret) => {
    graph[secret.id] = {
      secretId: secret.id,
      secretName: secret.name,
      output: { children: [], type: ReactiveParamType.SECRET },
      type: "secret",
    };
    paramDefinitions.set(secret.name, [secret.id]);
  });
  dataConnections.forEach(
    (d) =>
      (graph[d] = {
        dataConnectionId: d,
        outputTables: new Map(),
        type: "dataConnection",
      }),
  );

  // This can be keyed by CellId, since cells are the only thing that can reference another variable
  const missingDefinitions = new Map<VariableName, CellId[]>();

  for (let i = 0; i < sortedCells.length; i++) {
    const [cell, order] = sortedCells[i]!;
    const { references: referencesArray } = cells[cell.id]!;
    const cellId = cell.id;
    const graphNode: GraphNodeV3<C> = {
      cellId,
      type: "cell",
      cell,
      order,
      inputParams: new Map(),
      outputParams: new Map(),
    };
    graph[cellId] = graphNode;
    for (const references of referencesArray) {
      const { fallbackNode, getOutputInfo, parentNodeFilter } =
        getReferenceDetails({
          cellId,
          graph,
          paramDefinitions,
          references,
        });

      const { newParams, referencedParams } = references;

      // referencedParams are strictly references that are NOT defined in the
      // current cell (i.e if a reference is a newParam, it cannot be a referencedParam).
      // We include redefinedNewParams while constructing dependencies to capture cases where
      // variables are defined upstream and redefined again in the current cell.
      for (const { param: paramName } of referencedParams) {
        addReferenceDependency({
          cellId,
          fallbackNode,
          graph,
          graphNode,
          missingDefinitions,
          paramDefinitions,
          paramName,
          parentNodeFilter,
          references,
        });
      }
      // Make sure to handle newParams after we've handled referencedParams, so that if a variable is a redefinition
      // we link it to the previous cell before updating the most recent cell defition
      for (const { param } of newParams) {
        const existingDefinitions = paramDefinitions.get(param) ?? [];
        const outputInfo = getOutputInfo(param);
        outputInfo.shadowedNodeId = existingDefinitions[0];

        const existingOutput = graphNode.outputParams.get(param);
        if (existingOutput) {
          if (existingOutput.type !== outputInfo.type) {
            console.error(new Error(`Inconsistent types for param ${param}`));
          }
          if (existingOutput.dataConnectionId !== outputInfo.dataConnectionId) {
            console.error(
              new Error(`Inconsistent data connection ids for param ${param}`),
            );
          }
          if (existingOutput.shadowedNodeId !== outputInfo.shadowedNodeId) {
            console.error(new Error(`Inconsistent parents for param ${param}`));
          }
          continue;
        }

        // Always make sure to put the new definition at the beginning of the list
        paramDefinitions.set(param, [cellId, ...existingDefinitions]);
        graphNode.outputParams.set(param, outputInfo);
      }
    }
  }

  // Filter out to only params defined in multiple cells
  const redefinitions = new Map(
    Array.from(paramDefinitions).filter(([, nodeIds]) => nodeIds.length > 1),
  );
  return {
    missingDefinitions,
    redefinitions,
    graph,
  };
}

const getReferenceDetails = <C extends BaseGraphCellV3>({
  cellId,
  graph,
  paramDefinitions,
  references,
}: {
  cellId: CellId;
  graph: GraphV3<C>;
  paramDefinitions: Map<VariableName, GraphNodeV3Id[]>;
  references: ComputedCellReferencesV3;
}): {
  parentNodeFilter: ParentNodeFilter<C>;
  getOutputInfo: (param: VariableName) => OutputParamInfoV3;
  fallbackNode: GraphNodeV3<C> | null;
} => {
  // TODO: Refactor to make these defaults declarative and explicit per reference.type
  let fallbackNode: GraphNodeV3<C> | null = null;
  let parentNodeFilter: ParentNodeFilter<C> = () => true;
  let getOutputInfo: (param: VariableName) => OutputParamInfoV3 = () => ({
    children: [],
    type: ReactiveParamType.VARIABLE,
  });

  switch (references.type) {
    case "CODE": {
      const importSet = new Set(references.imports);
      const functionSet = new Set(references.functions.map((f) => f.name));
      getOutputInfo = (param) => {
        const type = importSet.has(param)
          ? ReactiveParamType.IMPORT
          : functionSet.has(param)
            ? ReactiveParamType.FUNCTION
            : ReactiveParamType.VARIABLE;
        return {
          children: [],
          type: type,
        };
      };
      break;
    }
    case "GENERIC": {
      // Generic doesn't have any special handling
      break;
    }
    case "NO_CODE": {
      const node = graph[cellId];
      const sourceParam = references.referencedParams[0];

      if (
        sourceParam != null &&
        node != null &&
        // pivot cells always output its own pivot result type
        node?.cell.cellType !== CellType.PIVOT
      ) {
        const parentOutput = getParentGraphNodeOutput({
          graph,
          paramDefinitions,
          variableName: sourceParam.param,
        });

        if (parentOutput?.type === ReactiveParamType.REMOTE_QUERY_RESULT) {
          getOutputInfo = () => {
            return {
              children: [],
              type: ReactiveParamType.REMOTE_QUERY_RESULT,
              dataConnectionId: parentOutput.dataConnectionId,
            };
          };
        } else if (
          references.isNoCodeDataFrame &&
          references.dataConnectionId != null
        ) {
          // this is needed to link the no code cell to the parent data connection node
          fallbackNode = graph[references.dataConnectionId] ?? null;

          getOutputInfo = () => {
            return {
              children: [],
              type: ReactiveParamType.REMOTE_QUERY_RESULT,
              dataConnectionId: references.dataConnectionId!, // null checked above
            };
          };
        }
      }
      break;
    }
    case "SQL": {
      if (!references.isDataFrame && references.dataConnectionId != null) {
        fallbackNode = graph[references.dataConnectionId] ?? null;
        // When parsing SQL references, only match against definitions that match the same data connection
        const connectionIdFilter: ParentNodeFilter<C> = (node, param) => {
          switch (node.type) {
            case "dataConnection":
              return node.dataConnectionId === references.dataConnectionId;
            case "cell":
              return (
                node.outputParams.get(param)?.dataConnectionId ===
                references.dataConnectionId
              );
            case "automaticParameter":
            case "secret":
              return false;
          }
        };
        const paramTypeFilter = getParamTypeFilter([
          ReactiveParamType.REMOTE_QUERY_RESULT,
          ReactiveParamType.QUERY_RESULT,
          ReactiveParamType.TABLE,
        ]);

        const sqlFilter: ParentNodeFilter<C> = (node, param) =>
          connectionIdFilter(node, param) && paramTypeFilter(node, param);

        const codeReferenceFilter: ParentNodeFilter<C> = (node, param) =>
          getParamTypeFilter([ReactiveParamType.VARIABLE])(node, param) &&
          node.type === "cell" &&
          node.cell?.cellType === CellType.CODE;

        parentNodeFilter = (node, param) =>
          sqlFilter(node, param) || codeReferenceFilter(node, param);
      }
      getOutputInfo = () => {
        return {
          children: [],
          dataConnectionId: references.dataConnectionId ?? undefined,
          type: references.preview
            ? ReactiveParamType.REMOTE_QUERY_RESULT
            : ReactiveParamType.QUERY_RESULT,
        };
      };
      break;
    }
    case "FILTER":
      getOutputInfo = () => {
        // It's a little unfortunate, but the filter cell has the semantics
        // that it is only in query mode if it is configured to be so (i.e.
        // references.preview) AND if its source is a query result.
        // Otherwise the cell would break if it were in query mode and the
        // upstream source's type changed from a query to a dataframe. So
        // we ignore the query mode setting if the source is not a query
        if (references.preview && references.sourceParam) {
          const outputInfo = getParentGraphNodeOutput({
            graph,
            paramDefinitions,
            variableName: references.sourceParam.param,
          });

          if (outputInfo?.type === ReactiveParamType.REMOTE_QUERY_RESULT) {
            return {
              children: [],
              type: ReactiveParamType.REMOTE_QUERY_RESULT,
              dataConnectionId: outputInfo.dataConnectionId,
            };
          } else if (
            references.isNoCodeDataFrame &&
            references.dataConnectionId != null
          ) {
            return {
              children: [],
              type: ReactiveParamType.REMOTE_QUERY_RESULT,
              dataConnectionId: references.dataConnectionId,
            };
          }
        }

        return {
          children: [],
          type: ReactiveParamType.QUERY_RESULT,
        };
      };
      break;
    default:
      assertNever(references, references);
  }

  return {
    parentNodeFilter,
    getOutputInfo,
    fallbackNode,
  };
};

function getParentGraphNodeOutput<C extends BaseGraphCellV3>({
  graph,
  paramDefinitions,
  variableName,
}: {
  variableName: VariableName;
  graph: GraphV3<C>;
  paramDefinitions: Map<VariableName, GraphNodeV3Id[]>;
}): OutputParamInfoV3 | undefined {
  const mostRecentParentId = (paramDefinitions.get(variableName) ?? []).find(
    (nodeId) => graph[nodeId],
  );
  const parentNode =
    mostRecentParentId != null ? graph[mostRecentParentId] : undefined;

  if (parentNode?.type === "cell") {
    return parentNode.outputParams.get(variableName);
  }
}

const addReferenceDependency = <C extends BaseGraphCellV3>({
  cellId,
  fallbackNode,
  graph,
  graphNode,
  missingDefinitions,
  paramDefinitions,
  paramName,
  parentNodeFilter,
  references,
}: {
  paramDefinitions: Map<VariableName, GraphNodeV3Id[]>;
  paramName: VariableName;
  references: ComputedCellReferencesV3;
  cellId: CellId;
  graphNode: CellGraphNodeV3<C>;
  graph: GraphV3<C>;
  parentNodeFilter: ParentNodeFilter<C>;
  fallbackNode: GraphNodeV3<C> | null;
  missingDefinitions: Map<VariableName, GraphNodeV3Id[]>;
}): void => {
  if (
    references.type === "SQL" &&
    references.isDataFrame &&
    paramName.includes(".")
  ) {
    // HACK: hack for now to skip referencing files in dataframe SQL.
    // TODO: Long term we should add files to the graph as its own type, and properly match against filenames
    return;
  }
  const existing = paramDefinitions.get(paramName) ?? [];
  const mostRecentParentId = existing.find((nodeId) => {
    const node = graph[nodeId];
    if (!node) {
      return false;
    }
    return parentNodeFilter(node, paramName);
  });
  const parentGraphNode: GraphNodeV3<C> | null =
    mostRecentParentId != null
      ? graph[mostRecentParentId] ?? null
      : fallbackNode;
  let type;
  let parentDataConnectionId;
  if (parentGraphNode) {
    let parentOutputInfo: OutputParamInfoV3 | null = null;
    // Pull the matching OutputParamInfo from the parent node, and determine the appropriate type of the parameter
    switch (parentGraphNode.type) {
      case "cell": {
        parentOutputInfo = parentGraphNode.outputParams.get(paramName) ?? null;
        // At this point it should be guaranteed that the parent node exists and knows about this param, since we got this via paramDefinitions
        assertIsDefined(parentOutputInfo);
        // If this is a reference to an existing parameter, instead just use the type of that one
        type = parentOutputInfo?.type ?? ReactiveParamType.VARIABLE;
        break;
      }
      case "automaticParameter": {
        parentOutputInfo = parentGraphNode.output;
        type = ReactiveParamType.AUTOMATIC_PARAM;
        break;
      }
      case "dataConnection": {
        parentOutputInfo = parentGraphNode.outputTables.get(paramName) ?? null;
        // Since data connection outputs are lazily added, if there is no mapping for the data connection yet add a new one
        if (parentOutputInfo == null) {
          parentOutputInfo = {
            children: [],
            dataConnectionId: parentGraphNode.dataConnectionId,
            type: ReactiveParamType.TABLE,
          };
          parentGraphNode.outputTables.set(paramName, parentOutputInfo);
        }
        type = ReactiveParamType.TABLE;
        break;
      }
      case "secret": {
        parentOutputInfo = parentGraphNode.output;
        type = ReactiveParamType.SECRET;
        break;
      }
      default:
        assertNever(parentGraphNode, parentGraphNode);
    }
    // Add our current cell as a child of the parent node if not already present
    if (!parentOutputInfo.children.includes(cellId)) {
      parentOutputInfo.children.push(cellId);
    }
    parentDataConnectionId = parentOutputInfo.dataConnectionId;
  } else {
    const missing = missingDefinitions.get(paramName);
    if (missing) {
      missing.push(cellId);
    } else {
      missingDefinitions.set(paramName, [cellId]);
    }
    type = ReactiveParamType.VARIABLE;
  }
  const existingInputInfo = graphNode.inputParams.get(paramName);
  const parentNodeId =
    parentGraphNode != null ? getGraphNodeV3Id(parentGraphNode) : undefined;
  if (
    existingInputInfo &&
    // SUP-1139: SQL parsing can have false positives, so prioritize references
    // of type TABLE below any other type of reference
    existingInputInfo.type !== ReactiveParamType.TABLE
  ) {
    if (existingInputInfo.type !== type) {
      console.error(new Error(`Inconsistent types for param ${paramName}`));
    }
    if (existingInputInfo.parentNodeId !== parentNodeId) {
      console.error(new Error(`Inconsistent parents for param ${paramName}`));
    }
  } else {
    graphNode.inputParams.set(paramName, {
      parentNodeId: parentNodeId,
      dataConnectionId: parentDataConnectionId,
      type,
    });
  }
};

interface TraverseGraphV3Args<C extends BaseGraphCellV3> {
  graph: GraphV3<C>;
  nodeIds: readonly GraphNodeV3Id[];
  direction: "downstream" | "upstream";
  includeOriginals?: boolean;
}

/**
 * Given a project graph, a starting point, and a direction, will use BFS to get an ordered list of all cells
 * in the given direction. Can also optionally include the starting cells in the list returned, or have them be omitted.
 */
export const traverseGraphV3FromNodesBFS = <C extends BaseGraphCellV3>({
  direction,
  graph,
  includeOriginals = true,
  nodeIds,
}: TraverseGraphV3Args<C>): GraphNodeV3<C>[] => {
  const result = [...nodeIds];
  for (let index = 0; result[index] != null; index++) {
    const currId = result[index];
    assertIsDefined(currId);
    const currNode = graph[currId];
    if (currNode != null) {
      let linkedNodeIds: GraphNodeV3Id[];
      const outputs = getGraphNodeV3Outputs(currNode);
      const inputs = getGraphNodeV3Inputs(currNode);
      switch (direction) {
        case "downstream":
          linkedNodeIds = [...outputs.values()].flatMap((outputParam) => [
            ...outputParam.children,
          ]);
          break;
        case "upstream":
          linkedNodeIds = [...inputs.values()]
            .map((inputParam) => inputParam.parentNodeId)
            .filter((parentId): parentId is GraphNodeV3Id => parentId != null);
          break;
        default:
          assertNever(direction, direction);
      }
      for (const linkedNodeId of linkedNodeIds) {
        if (!result.includes(linkedNodeId)) {
          result.push(linkedNodeId);
        }
      }
    }
  }
  if (!includeOriginals) {
    result.splice(0, nodeIds.length);
  }

  // Filter out the nodes to only cells, and then order them based on their direction
  return [...result]
    .map((nodeId) => graph[nodeId])
    .filter(notEmpty)
    .sort((a, b) =>
      direction === "downstream"
        ? sortGraphNodeV3s(a, b)
        : sortGraphNodeV3s(b, a),
    );
};

/**
 * Same as traverseGraphV3FromNodesBFS except filters to only cells
 */
export const traverseGraphV3CellsFromNodesBFS = <C extends BaseGraphCellV3>(
  args: TraverseGraphV3Args<C>,
): C[] => {
  return traverseGraphV3FromNodesBFS(args)
    .filter((n): n is CellGraphNodeV3<C> => n?.type === "cell")
    .map((c) => c.cell);
};

export function getAllVariableNamesFromGraph<C extends BaseGraphCellV3>(
  cells: ComputedCellReferenceMapV3<C>,
): VariableName[] {
  const variableNames = new Set<VariableName>();
  for (const cell of Object.values(cells)) {
    for (const reference of cell.references) {
      for (const param of reference.newParams) {
        variableNames.add(param.param);
      }
    }
  }
  return [...variableNames];
}
